// src/auth/auth.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch("/api/user", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.user.email_verified) {
          setUser(data.user);
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
          setUser({});
        }
      } else {
        console.error("Failed to fetch user profile");
        setLoggedIn(false);
        setUser({});
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setLoggedIn(false);
      setUser({});
    } finally {
      setLoading(false);
    }
  };
  
  const login = (userInfo) => {
    setLoggedIn(true);
    setUser(userInfo);
    const subscription = userInfo.subscription;
    const hasActiveSubscription =
      subscription &&
      subscription.status === 'active' &&
      subscription.plan !== 'trial' &&
      subscription.plan !== null;
  
    if (!hasActiveSubscription) {
      navigate('/app/subscription');
    } else if (!userInfo.onboardingCompleted) {
      navigate('/app/onboarding');
    } else {
      navigate('/app/home');
    }
  };

  const logout = async () => {
    try {
      const response = await fetch("/api/logout", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        setLoggedIn(false);
        setUser({});
        navigate("/");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const updateUser = (updatedData) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedData }));
  };

  return (
    <AuthContext.Provider value={{ loggedIn, user, login, logout, updateUser, loading, fetchUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  return useContext(AuthContext);
}
