// src/App.js
import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import { AuthProvider } from './auth/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const routing = useRoutes(routes);

  return (
    <GoogleOAuthProvider clientId="941181622781-s622ahuvlrbdd8406g7k4fd0k39mir3v.apps.googleusercontent.com">
      <AuthProvider>
          {routing}
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;