// src/routes/PrivateRoute.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import PropTypes from 'prop-types';
import CircularLoader from '../components/core/CircularLoader';

const PrivateRoute = ({ children }) => {
  const { loggedIn, loading, user } = useAuth();
  const location = useLocation();

  if (loading) {
    return <CircularLoader />;
  }

  if (!loggedIn) {
    // Redirect to login page and pass the current location for post-login redirect
    return <Navigate to="/auth/login" replace state={{ from: location }} />;
  }

  const subscription = user.subscription;
  const hasActiveSubscription =
    subscription &&
    subscription.status === 'active' &&
    subscription.plan !== 'trial' &&
    subscription.plan !== null;

  // If user does not have an active paid subscription, redirect to subscription page
  if (!hasActiveSubscription && location.pathname !== '/app/subscription') {
    return <Navigate to="/app/subscription" replace />;
  }

  // If user has an active paid subscription but hasn't completed onboarding, redirect to onboarding
  if (
    hasActiveSubscription &&
    !user.onboardingCompleted &&
    location.pathname !== '/app/onboarding'
  ) {
    return <Navigate to="/app/onboarding" replace />;
  }

  // If user is on onboarding page but has completed onboarding, redirect to home
  if (
    hasActiveSubscription &&
    user.onboardingCompleted &&
    location.pathname === '/app/onboarding'
  ) {
    return <Navigate to="/app/home" replace />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
