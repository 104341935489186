// src/components/core/CircularLoader.js

import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/system';
import PropTypes from 'prop-types';

export default function CircularLoader({ message = 'Loading', size = '6rem', textVariant = 'h5' }) {
  // Fade-in animation for the entire loader
  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

  // Animation for the ellipsis
  const ellipsisAnimation = keyframes`
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
    100% { content: '.'; }
  `;

  return (
    <Box
      role="alert"
      aria-busy="true"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        bgcolor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background for overlay effect
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300, // Ensures the loader is above other elements
        animation: `${fadeIn} 0.5s ease-in-out`, // Fade-in effect
      }}
    >
      <Stack spacing={4} direction="column" alignItems="center">
        {/* Loader */}
        <CircularProgress size={size} color="primary" />

        {/* Loading Text with Animated Ellipsis */}
        <Typography
          variant={textVariant}
          component="div"
          sx={{
            mt: 2,
            fontFamily: 'Poppins, sans-serif', // Ensure "Poppins" is loaded
            fontWeight: 600,
            color: 'text.primary',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            animation: `${fadeIn} 1s ease-in-out`, // Optional: Fade-in for text
          }}
        >
          {message}
          <Box
            component="span"
            sx={{
              ml: 1, // Margin-left to separate dots from text
              '&::after': {
                content: '""', // Initial empty content
                display: 'inline-block',
                width: '1ch', // Width to fit one dot
                animation: `${ellipsisAnimation} 1.5s steps(4, end) infinite`, // Ellipsis animation
              },
            }}
          />
        </Typography>
      </Stack>
    </Box>
  );
}

CircularLoader.propTypes = {
  message: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textVariant: PropTypes.string,
};
