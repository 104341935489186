// src/auth/AuthCheck.js

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './auth';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularLoader from '../components/core/CircularLoader';

const AuthCheck = ({ children }) => {
  const { loggedIn, loading, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      if (loggedIn) {
        const subscription = user.subscription;
        const hasActiveSubscription =
          subscription &&
          subscription.status === 'active' &&
          subscription.plan !== 'trial' &&
          subscription.plan !== null;

        if (!hasActiveSubscription && location.pathname !== '/app/subscription') {
          navigate('/app/subscription', { replace: true });
        } else if (
          hasActiveSubscription &&
          !user.onboardingCompleted &&
          location.pathname !== '/app/onboarding'
        ) {
          navigate('/app/onboarding', { replace: true });
        }
      }
    }
  }, [loggedIn, loading, navigate, user, location]);

  if (loading) {
    return <CircularLoader />;
  }

  return <>{children}</>;
};

AuthCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthCheck;
