// src/routes/routes.js

import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import PrivateRoute from './PrivateRoute';
import AuthCheck from '../auth/AuthCheck';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const LandingLayout = Loadable(lazy(() => import('../landing/LandingLayout')));

/* ****Pages***** */
const Layout = Loadable(lazy(() => import('../layout/Layout')));
const Home = Loadable(lazy(() => import('../views/home/Home')));
const Meetings = Loadable(lazy(() => import('../views/meetings/Meetings')));
const Topics = Loadable(lazy(() => import('../views/topics/Topics')));
const FeedbackProgress = Loadable(lazy(() => import('../views/feedbackProgress/FeedbackProgress')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const MagicLinkVerifier = Loadable(lazy(() => import('../views/authentication/MagicLinkVerifier')));
const MarketingPage = Loadable(lazy(() => import('../landing/MarketingPage')));
const Settings = Loadable(lazy(() => import('../views/settings/Settings')));
const Onboarding = Loadable(lazy(() => import('../views/onboarding/Onboarding')));

/* ***Subscription Pages*** */
const SubscriptionPage = Loadable(lazy(() => import('../views/subscription/SubscriptionPage')));
const SubscriptionSuccess = Loadable(lazy(() => import('../views/subscription/SubscriptionSuccess')));
const SubscriptionCancel = Loadable(lazy(() => import('../views/subscription/SubscriptionCancel')));
const Subscription = Loadable(lazy(() => import('../views/subscription/Subscription')));

/* ***New Pages Imports*** */
const HowItWorks = Loadable(lazy(() => import('../landing/pages/HowItWorks')));
const GetInTouch = Loadable(lazy(() => import('../landing/pages/GetInTouch')));
const Blog = Loadable(lazy(() => import('../landing/pages/Blog')));
const BlogArticle = Loadable(lazy(() => import('../landing/pages/BlogArticle')));
const UseCases = Loadable(lazy(() => import('../landing/pages/UseCases')));
const UseCaseArticle = Loadable(lazy(() => import('../landing/pages/UseCaseArticle')));

/* ***Legal Pages Imports*** */
const TermsOfService = Loadable(lazy(() => import('../landing/pages/TermsOfService')));
const PrivacyPolicy = Loadable(lazy(() => import('../landing/pages/PrivacyPolicy')));
const AcceptableUsePolicy = Loadable(lazy(() => import('../landing/pages/AcceptableUsePolicy')));

const routes = [
  {
    path: '/',
    element: (
      <AuthCheck>
        <LandingLayout />
      </AuthCheck>
    ),
    children: [
      { index: true, element: <MarketingPage /> },
      { path: 'how-it-works', element: <HowItWorks /> },
      { path: 'get-in-touch', element: <GetInTouch /> },
      { path: 'blog', element: <Blog /> },
      { path: 'blog/:id', element: <BlogArticle /> },
      { path: 'use-cases', element: <UseCases /> },
      { path: 'use-cases/:id', element: <UseCaseArticle /> },
      /* **Legal Pages** */
      { path: 'terms', element: <TermsOfService /> },
      { path: 'privacy', element: <PrivacyPolicy /> },
      { path: 'acceptable-use', element: <AcceptableUsePolicy /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/app',
    element: <Layout />,
    children: [
      { path: '', element: <Navigate to="home" /> },
      {
        path: 'home',
        exact: true,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: 'meetings',
        exact: true,
        element: (
          <PrivateRoute>
            <Meetings />
          </PrivateRoute>
        ),
      },
      {
        path: 'topics',
        exact: true,
        element: (
          <PrivateRoute>
            <Topics />
          </PrivateRoute>
        ),
      },
      {
        path: 'feedback-progress',
        exact: true,
        element: (
          <PrivateRoute>
            <FeedbackProgress />
          </PrivateRoute>
        ),
      },
      {
        path: 'settings',
        exact: true,
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
      },
      {
        path: 'onboarding',
        exact: true,
        element: (
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        ),
      },
      {
        path: 'subscription',
        element: (
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        ),
        children: [
          { index: true, element: <SubscriptionPage /> },
          { path: 'success', element: <SubscriptionSuccess /> },
          { path: 'cancel', element: <SubscriptionCancel /> },
        ],
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'verify-magic-link', element: <MagicLinkVerifier /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];

export default routes;
