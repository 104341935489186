import React, { Suspense } from "react";
import CircularLoader from 'components/core/CircularLoader';

const Loadable = (Component) => {
  const LoadableComponent = (props) => (
    <Suspense fallback={<CircularLoader />}>
      <Component {...props} />
    </Suspense>
  );

  LoadableComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;

  return LoadableComponent;
};



export default Loadable;
